export default {
    ERROR_FETCHING_BOOKS: 'The book may be restricted or unavailable. Try rewording your search.',
    ERROR_FETCHING_BOOK_DETAILS: "Couldn't load book details. Please try again.",
    ERROR_DURING_CERTIFICATION: 'An error occurred during certification. Retry or contact support if this continues.',
    ERROR_LOGIN_FAILED_TRY_AGAIN: 'Login failed. Please try again.',
    ERROR_FETCHING_PROFILE: 'Error fetching profile',
    ERROR_PROFILE_ID_IS_NULL: 'Profile ID cannot be empty',
    ERROR_FETCHING_USER_PROFILE: 'Error fetching user profile',
    ERROR_UPDATING_USER_PROFILE: 'Error updating user profile',
    ERROR_FETCHING_CERTIFIED_BOOKS: 'Certified books are unavailable. Refresh or try again later.',
    ERROR_FETCHING_CERTIFICATE: "Couldn't fetch your certificate. Please try later.",
    ERROR_SUBMITING_ANSWER: "We couldn't process your answer. Please try again.",
    ERROR_FETCHING_QUESTION: "Couldn't load the question. Please refresh or try again.",
    ERROR_FETCHING_CERTIFICATE_DETAIL: "Couldn't load certificate details. Please try again.",
    ERROR_OCCURED: 'Error occurred',
    ERROR_INTERNAL_SERVER: 'An internal server error occurred. Please try again later.',
    ERROR_TIMEOUT: 'No response from the server. Please check your network connection.',
    ERROR_FETCHING_QUESTION_ANSWER: 'Error fetching answer',
    ERROR_FETCHING_FAVOURITE_BOOKS: "Couldn't load favorite books. Please refresh.",
    ERROR_FETCHING_FEEDBACK: "Couldn't load feedback. Please try later.",


    INFO_LOGIN_EMAIL_MANDATORY: 'Please enter your email.',
    INFO_PROFILE_UPDATED_SUCCESSFULY: 'Profile successfully updated',
    INFO_CERTIFIED_BOOK_NOT_EXIST: 'No certified books found in your account.',
    INFO_LOGIN_FAILED: 'Login failed',
    INFO_NO_DATA_EXIST: "The data you're looking for doesn't exist. Refine your search.",
    INFO_UNAUTHORIZE_ATTEMPT: 'Unauthorized access. Redirecting to Login.',

    PLACEHOLDER_SEARCH_BOOK: 'Search for any book or author',
    PLACEHOLDER_LOGIN_EMAIL: 'Email',
    PLACEHOLDER_LOGIN_PASSWORD: 'Password',
    PLACEHOLDER_CERTIFICATE_NAME: 'Enter the name you want on your certificates',
    PLACEHOLDER_CERTIFICATE_TOP_SKILLS: 'Top skills & concepts covered',
    PLACEHOLDER_CERTIFICATE_TWO_LINE_CONTENT: "For successfully mastering the key concepts from the book '<bookname>' as validated by the assessment.",

    MENU_DASHBOARD_TITLE: 'Dashboard',
    MENU_MYCERT_TITLE: 'MyCerts',
    MENU_SETTINGS_TITLE: 'Settings',

    GOOGLE_LOGIN_PROVIDER: 'Google',
    KNOWELA_LOGIN_PROVIDER: 'Knowela',
}