import React, { useState, useEffect, useRef } from "react";
import styles from "../Css/Navbar.module.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt, faTimes, faCopy } from "@fortawesome/free-solid-svg-icons"; // import faTimes for close icon
import ConstantColors from "../Constants/Colors";
import knowela_logo from "../img/Knowela_logo.png"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from 'react-share';

const Navbar = ({ children, onLogout }) => {
  const navigate = useNavigate();
  const [isShareWidgetOpen, setIsShareWidgetOpen] = useState(false);
  const [token, setToken] = useState(null);
  const shareWidgetRef = useRef(null); // Create a ref for the share widget

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareWidgetRef.current && !shareWidgetRef.current.contains(event.target)) {
        setIsShareWidgetOpen(false); // Close the widget if clicked outside
      }
    };
    if (isShareWidgetOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isShareWidgetOpen]);

  const Logout = () => {
    onLogout();
    setToken(null);
    navigate('/');
  };

  const toggleShareWidget = () => {
    setIsShareWidgetOpen(!isShareWidgetOpen);
  };

  const handleShareClick = () => {
    setIsShareWidgetOpen(false);
  };

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert("URL copied to clipboard!");
    });
  };

  const currentUrl = window.location.origin;

  return (
    <div className={styles.container}>
      <div className={styles.topStrip}>
        <p>
          Facing issues, got ideas, or want to chat? Reach out to us{' '}
          <a href={process.env.REACT_APP_SYSTEM_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
            here!
          </a>
        </p>
      </div>
      <div className={styles.top_bar}>
        <div className={styles.top_bar1}>
          <div className={styles.top_bar_logo}>
            <button className={styles.nav_link_knowela} >
              <img src={knowela_logo} alt="Logo" onClick={() => navigate("/")} />
            </button>
            <p className={styles.experimental}>EXPERIMENTAL</p>
          </div>
        </div>
        <div className={styles.top_bar2}>
          <button className={styles.nav_link} onClick={() => navigate("/about")}>About</button>
          {token ? (
            <button className={styles.nav_link} onClick={() => navigate("/mycert")}>Dashboard</button>
          ) : (
            <button className={styles.nav_link} onClick={() => navigate("/whycertify")}>Why Certify</button>
          )}
          <button className={styles.nav_link} onClick={() => navigate("/method")}>Method</button>
          <button className={styles.nav_link_share} onClick={toggleShareWidget}>
            {/* <FontAwesomeIcon style={{
              color: ConstantColors.MENU_SHARE_IMAGE_COLOR, paddingRight: 5
            }} icon={faShareAlt} /> */}
            Share
          </button>
          {isShareWidgetOpen && (
            <div ref={shareWidgetRef} className={styles.shareWidget}>
              <div className={styles.shareWidgetHeader}>
                <FontAwesomeIcon
                  icon={faTimes}
                  className={styles.closeIcon}
                  onClick={toggleShareWidget}
                  title="Close"
                />
              </div>
              <div className={styles.shareButtonsContainer}>
                <div className={styles.copyIconDiv}>
                  <div className={styles.copyIconContainer}>
                    <FontAwesomeIcon
                      icon={faCopy}
                      className={styles.copyIcon}
                      onClick={copyUrlToClipboard}
                      title="Copy Link"
                    ></FontAwesomeIcon>
                  </div>
                  <div>
                    <p>Copy Link</p>
                  </div>
                </div>
                <WhatsappShareButton url={currentUrl} className={styles.shareButton} onClick={handleShareClick}>
                  <WhatsappIcon size={32} round />
                  <p>Whatsapp</p>
                </WhatsappShareButton>
                <TwitterShareButton url={currentUrl} className={styles.shareButton} onClick={handleShareClick}>
                  <TwitterIcon size={32} round />
                  <p>Twitter</p>
                </TwitterShareButton>
                <FacebookShareButton url={currentUrl} className={styles.shareButton} onClick={handleShareClick}>
                  <FacebookIcon size={32} round />
                  <p>Facebook</p>
                </FacebookShareButton>
                <LinkedinShareButton url={currentUrl} className={styles.shareButton} onClick={handleShareClick}>
                  <LinkedinIcon size={32} round />
                  <p>LinkedIn</p>
                </LinkedinShareButton>
              </div>
            </div>
          )}
          {token ? (
            <button className={styles.nav_link} onClick={Logout}>Logout</button>
          ) : (
            <button className={`${styles.nav_link} ${styles.disabled}`} disabled></button>
          )}
        </div>
      </div>
      <div className={styles.contentWrapper}>{children}</div>
    </div >
  );
};

export default Navbar;
