import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faCheckCircle,
  faThLarge,
  faPen,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../Css/Setting.module.css";
import { useNavigate } from "react-router-dom";
import ProfileService from "../Services/ProfileService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConstantColors from "../Constants/Colors";
import ConstantStrings from "../Constants/Strings";

const Setting = () => {
  const url = window.location.origin;
  const hasFetchedUserProfile = useRef(false);
  const navigate = useNavigate();
  const [userprofile, setUserProfile] = useState({});
  const [userprofile_id, setUserProfile_id] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [newProfileId, setNewProfileId] = useState("");
  const [certificate_name, setCertificate_name] = useState("");
  const [new_certificate_name, setNewCertificate_name] = useState("");

  useEffect(() => {
    if (!hasFetchedUserProfile.current) {
      UserProfileData();
      hasFetchedUserProfile.current = true; // Mark as fetched
    }
  }, []);

  const UserProfileData = async () => {
    try {
      const response = await ProfileService.UserProfile();
      if (response) {
        setUserProfile(response);
        setUserProfile_id(response.profile_id);
        setCertificate_name(response.certificate_name);
        setNewCertificate_name(response.certificate_name);
      }
    } catch (error) {
      console.error(`${ConstantStrings.ERROR_FETCHING_PROFILE}:`, error);
    }
  };

  // Function to handle profile ID change
  const handleProfileIdChange = (e) => {
    setNewProfileId(e.target.value);
  };

  const handleCertNameChange = (e) => {
    setNewCertificate_name(e.target.value);
  };

  // Function to handle "OK" button in modal
  const handleOkClick = () => {
    if (!newProfileId) {
      toast.success(ConstantStrings.ERROR_PROFILE_ID_IS_NULL);
    } else {
      setUserProfile_id(newProfileId);
      setIsModalOpen(false);
    }
  };

  const handleCancelClick = async () => {
    await UserProfileData();
  };

  const UpdatedUserProfileData = async () => {
    const finalProfileId = newProfileId || userprofile_id;
    const finalCertName = new_certificate_name || certificate_name;

    try {
      const response = await ProfileService.UpdatedUserProfile(finalCertName, finalProfileId);
      if (response) {
        setUserProfile(response);
        setUserProfile_id(response.profile_id);
        setCertificate_name(response.certificate_name);
        setNewProfileId(response.profile_id);
        setNewCertificate_name(response.certificate_name);
        toast.success(ConstantStrings.INFO_PROFILE_UPDATED_SUCCESSFULY);
      }
    } catch (error) {
      console.error(`${ConstantStrings.ERROR_FETCHING_PROFILE}:`, error);
    }
  };

  return (
    <div className={styles.settingsContainer}>
      <div className="topBar">
        <button className="button"
          onClick={() => navigate("/dashboard")}>
          <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faThLarge} />
          <span>{ConstantStrings.MENU_DASHBOARD_TITLE}</span>
        </button>
        <button className="button"
          onClick={() => navigate("/mycert")}>
          <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faCheckCircle} />
          <span>{ConstantStrings.MENU_MYCERT_TITLE}</span>
        </button>
        <button
          className="button"
          onClick={() => navigate("/setting")}
        >
          <FontAwesomeIcon color={ConstantColors.DASHBOARD_TOP_BAR_FONT_COLOR} icon={faCog} />
          <span>{ConstantStrings.MENU_SETTINGS_TITLE}</span>
        </button>
      </div>

      <div className={styles.inputGroup}>
        <label htmlFor="name">Name </label>
        <p className={styles.profile}>{userprofile.name}</p>
      </div>
      {
  userprofile.certificate_name_locked ? (
    <div className={styles.inputGroup}>
      <label htmlFor="certname">Name on certificates</label>
      <input
        type="text"
        id="certname"
        name="certname"
        value={new_certificate_name}
        onChange={handleCertNameChange}
        placeholder={ConstantStrings.PLACEHOLDER_CERTIFICATE_NAME}
        disabled // Disable the field
      />
      <p className={styles.warningLine}>The name has been locked and cannot be changed.</p>
    </div>
  ) : (
    <div className={styles.inputGroup}>
      <label htmlFor="certname">Name on certificates</label>
      <input
        type="text"
        id="certname"
        name="certname"
        value={new_certificate_name}
        onChange={handleCertNameChange}
        placeholder={ConstantStrings.PLACEHOLDER_CERTIFICATE_NAME}
      />
      <p className={styles.warningLine}>Note: This name cannot be changed once saved.</p>
    </div>
  )
}


{/* Email Input */ }
<div className={styles.inputGroup}>
  <label htmlFor="email">Email</label>
  <p className={styles.profile}>{userprofile.email_address}</p>
</div>

{/* Public Profile Link Input */ }
<div className={styles.inputGroup}>
  <label htmlFor="publicProfile">Public Profile Link</label>
  <div className={styles.profileLinkContainer}>
    {/* <p className={styles.profile}>{url}/profile/{userprofile_id}</p> */} 
    <p className={styles.profile}>This is an upcoming feature</p>
    {/* <FontAwesomeIcon
      onClick={() => setIsModalOpen(true)} // Open modal on click
      icon={faPen}
      className={styles.editIcon}
    /> */}
  </div>
  <button onClick={UpdatedUserProfileData} className={styles.okButton}>
    Save
  </button>
  <button onClick={handleCancelClick} className={styles.cancelButton}>
    Cancel
  </button>
</div>

{/* Modal for editing Profile ID */ }
{
  isModalOpen && (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>Edit Profile ID</h2>
        <input
          type="text"
          value={newProfileId}
          onChange={handleProfileIdChange}
          placeholder={userprofile_id}
          className={styles.inputField}
        />
        <button onClick={handleOkClick} className={styles.okButton}>
          OK
        </button>
        <button onClick={() => setIsModalOpen(false)} className={styles.cancelButton}>
          Cancel
        </button>
      </div>
    </div>
  )
}
<ToastContainer />
    </div >
  );
};

export default Setting;
