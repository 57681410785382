import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate,useLocation } from "react-router-dom";
import About from "./Screens/About";
import Navbar from "./Screens/Navbar";
import WhyCertify from "./Screens/WhyCertify";
import Method from "./Screens/Method";
import Share from "./Screens/Share";
import Setting from "./Screens/Setting";
import Home from "./Screens/Home";
import BookList from "./Screens/BookList";
import StartCert from "./Screens/StartCert";
import Questions from "./Screens/Questions";
import Results from "./Screens/Result";
import LoginPage from "./Screens/LoginPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MyCerts from "./Screens/MyCerts";
import Dashboard from "./Screens/Dashboard";
import Profile from "./Screens/Profile";
import './App.css';



const App = () => {
  const location = useLocation();
  
    useEffect(() => {
      // track pageview with gtag / react-ga / react-ga4, for example:
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }, [location]);

  const [token, setToken] = useState(null); // Track token instead of isLoggedIn

  // Check if user is logged in (token exists) on page load
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken); // Set token if it exists in localStorage
    }
  }, []);
  const clearTokenOnClose = () => {
    // Remove the token from localStorage
    localStorage.removeItem("token");
  };
  useEffect(() => {
    // When the component mounts, set a flag in sessionStorage
    sessionStorage.setItem("isRefresh", "true");

    // Handle the beforeunload event to check if it's a page refresh
    const handleBeforeUnload = (event) => {
      // Check if the sessionStorage flag is present
      const isRefresh = sessionStorage.getItem("isRefresh");

      if (!isRefresh) {
        // If the flag is missing, it means the user is closing the window/tab, not refreshing
        clearTokenOnClose();
      }
    };

    // Attach the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Function to handle login success
  const handleLoginSuccess = (apiToken) => {
    setToken(apiToken);
    localStorage.setItem("token", apiToken); // Save token to localStorage
  };

  // Function to handle logout
  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("feedback");
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GMAIL_CLIENT_ID}>
        <Navbar onLogout={handleLogout}>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/method" element={<Method />} />
            <Route path="/whycertify" element={<WhyCertify />} />

            {!token ? (
              // If no token, show the LoginPage
              <Route
                path="/"
                element={<Home onLoginSuccess={handleLoginSuccess} />}
              />

            ) : (
              // If token exists, wrap all routes inside the Navbar
              <Route
                path="*"
                element={
                  <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/whycertify" element={<WhyCertify />} />
                    <Route path="/method" element={<Method />} />
                    <Route path="/share" element={<Share />} />
                    <Route path="/setting" element={<Setting />} />
                    <Route path="/booklist" element={<BookList />} />
                    <Route path="/startcert" element={<StartCert />} />
                    <Route path="/question" element={<Questions />} />
                    <Route path="/result" element={<Results />} />
                    <Route path="/mycert" element={<MyCerts />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/profile/:userId" element={<Profile />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="*" element={<Navigate to="/home" />} />
                  </Routes>
                }
              />
            )}
          </Routes>
        </Navbar>
    </GoogleOAuthProvider>
  );
};

export default App;
